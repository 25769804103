<template>
  <div class="main container-fluid p-0">
    <h2>{{ title }}</h2>
    <img class="img-fluid" :src="require(`@/assets/images/${img}`)" alt="Luca-Garlaschelli">
  </div>
</template>

<script>
export default {
  name: 'Jumbotron',
  props:{
    title: String,
    img: String
  }

}
</script>

<style lang="scss" scoped>
.main{
  margin-top: 55px;
  position: relative;
  h2{
    position: absolute;
    color: #ddd;
    top:20px;
    left: 10px;
    text-transform: uppercase;
    font-size: 20px;
  }
}
</style>