<template>
  <div class="main">
  <Jumbotron
    :title="getTitle"
    img="Luca-Garlaschelli-about.jpg"
  />
  <div v-if="lang === 'it'" class="container">
    <h5>CONTRABBASSISTA - COMPOSITORE - INSEGNANTE</h5>
<h3>Luca Garlaschelli </h3>
<div class="bio">
L’attività di Luca Garlaschelli ha inizio con la frequentazione di  Siena Jazz e il corso di contrabbasso presso il Conservatorio G. Nicolini di Piacenza, dove si diploma nel 1989. Le sue collaborazioni sono numerose: dal pianista Piero Bassini, ad Arrigo Cappelletti, Furio Romano, Carlo Bagnoli, Paolo Tomelleri, Umberto Petrin ed altri.  Si esibisce in prestigiosi Teatri collaborando con il baritono Leo Nucci (Teatro dell’Opera di Roma, Teatro Sistina, Teatro Dante Alighieri, Conservatorio di Zagabria a Sabbioneta). <br>
Nel frattempo inizia a collaborare con  Moni Ovadia nell’ambito della musica popolare, con performances al Teatro  Petruzzelli di Bari, al Teatro dei Filodrammatici di Milano, al Mama’s Theater di New York ed al Festival Internazionale del Cinema a Venezia.<br>
Il legame con Moni Ovadia si consolida divenendo una collaborazione che perdura nei decenni e che si è trasformata anche in una profonda amicizia. 
<hr>
Nel luglio del 1999 consegue il Diploma di Alto Perfezionamento di Musica Jazz presso il Conservatorio A. Boito di Parma. Le collaborazioni di Luca Garlaschelli in ambito jazzistico sono innumerevoli: Harold Land, Bruce Forman, Jimmy Cobb, Giulio Capiozzo, Tullio De Piscopo, Piero Bassini, Paolo Fresu, Tiziana Ghiglioni, Enrico Rava, Gaetano Liguori, Franco Cerri, Enrico Intra, Ettore Fioravanti, Antonello Salis, G. Luigi Trovesi ed altri. Effettua tour con grandi artisti come Steve Turre (Agosto ’91), con Bruce Forman (Ottobre ’92, Luglio ’93, Ottobre ’94, Luglio ’95, Novembre ’98), con Jimmy Owens (Luglio ’96, Luglio ’98), con George Cables (Febbraio ’99), Tony Scott (Aprile ’98, Agosto ’99).  Realizza incisioni con Gianni Coscia, Bruno De Filippi, Renato Sellani, Piero Bassini, Roberto Gatto, Ettore Fioravanti, Moni Ovadia, Janosh Husur, Vladimir Denissenkov, Gaetano Liguori, Mario Arcari, Gabriele Mirabassi.  Ha tenuto concerti a Cuba, in Germania, in Svizzera, in Francia, negli Stati Uniti, in Polonia, in Jugoslavia, Turchia, Spagna, Danimarca e Libano.
<hr>
Nel 1998 fonda la <strong>Musikorchestra</strong>, quartetto formato da: Luca Garlaschelli al contrabbasso e/o al bassetto (un violoncello accordato a quarte), Enzo Rocco alla chitarra acustica, Renata “Tatoo” Vinci al clarinetto- clarinetto basso-sax soprano e Ferdinando Faraò alle percussioni. <br>
Il repertorio proposto va dalla musica Klezmer alla habanera, dal jazz alla bossa nova, dal tango alla musica popolare romena.
<hr>
Il rapporto di Luca Garlaschelli con il teatro si fa sempre più stretto: “Moni Waltz”, dedicato da Garlaschelli a Moni Ovadia,  è diventato il motivo conduttore dello spettacolo teatrale firmato da Ovadia “Mame mamele mama. Il crepuscolo delle madri”.    
<hr>
Le sue collaborazioni musicali jazzistiche, unitamente ai lavori teatrali, abbracciano artisti di tutto il mondo (Franco Cerri, Gianni Basso, Gaetano Liguori, Jimmy Owens, Tilmann Dehnhard) e danno vita a numerosi album: 
<hr>
Nell’estate del 1999 esce per le edizioni discografiche Audiar il cd <strong>“Dont’ forget…” </strong> della Musikorchestra, in cui partecipano come ospiti Moni Ovadia, Tiziana Ghiglioni e Vladimir Denissenkov.
<hr>
Nel 2000 la Musikorchestra partecipa al cd prodotto da Audiar <strong>“Escuela de Oficios”</strong>, musicando la celebre poesia cubana “La Muralla” insieme a musicisti cubani.
<hr>
Nell’estate del 2001 esce il cd <strong>“The suond of dream”</strong> che è stato presentato a Cannes, Lugano, Bergamo, Piacenza, Arcore, Cassano D’Adda.
<hr>
Nel 2002 partecipa all’allestimento de <strong>“Il violinista sul tetto”</strong> diretto da Moni Ovadia e nel 2003 all’allestimento de <strong>”L’Armata a cavallo”</strong>, sempre di Ovadia. 
<hr>
Nel frattempo incide con il trio di Gaetano Liguori il cd <strong>“Il Comandante”</strong>, presentato al salone dell’Unesco a Beirut per i 20 anni dalla strage di Sabra e Chatila.
<hr>
Nel 2004 esce il terzo cd della Musikorchestra intitolato <strong>“Salam Alaykum”</strong> che riscuote un ottimo successo di critica.
<hr>
Nel 2005 e 2006 partecipa a due spettacoli teatrali con Moni Ovadia : <strong>“Es iz Amerike!”</strong> e “<strong>Le storie del Signor Keuner”</strong>.
<hr>
Nel marzo del 2007 esce il cd-dvd <strong>“Mai tardi”</strong>  con la Musikorchestra.
<hr>
Continua l’attività di insegnante di Orchestra Jazz presso il Crams di Lecco.
<hr>
Nell’aprile del 2007 si laurea in contrabbasso solista presso il Conservatorio G. Nicolini di Piacenza.
<hr>
Nell’ottobre del 2009 si laurea in contrabbasso jazz presso il Conservatorio G. Verdi di Milano..
<hr>
Nel 2010 per il cd <strong>“Mingus in Strings”</strong>, edito dalla SNJ, in cui viene proposta una rivisitazione di brani di Charles Mingus per quintetto d’archi, Garlaschelli viene segnalato nel Top Jazz della rivista Musica Jazz come contrabbassista ed arrangiatore.
<hr>
Nel 2012 esce per la SNJ <strong>“Duology”</strong>, in duo con il sassofonista Claudio Fasoli.
<hr>
Continua la sua attività concertistica. Nel 2012 incide, come contrabbassista e arrangiatore, “Verdi in jazz”  e  nel 2014 <strong>“Tammuriata nera, Napoli canzoni e jazz”</strong>.
<hr>
Sempre nel 2014 si esibisce al teatro S. Martin di Buenos Aires con la Stage Orchestra di Moni Ovadia e nel novembre dello stesso anno è a New York presso l’Istituto di Cultura Italiano  con il progetto <strong>“Jazzin’ Around Baroque” </strong>(di cui è contrabbassista e  arrangiatore).
<hr>
Continua con le collaborazioni teatrali suonando e componendo musica con Moni Ovadia con cui partecipa al tour teatrale dal 2018 al 2020 con lo spettacolo “Dio ride”, con Cochi Ponzoni con lo spettacolo <strong>“Magellano”</strong>, e con “Sex and disabled people” con la regia di Luciana Littizzetto (con cui si esibisce a Madrid).
<hr>
Dal 2015 insegna nei Conservatori di Napoli, Alessandria, Potenza, Bologna, Mantova e Cosenza dove a tutt’oggi ha la cattedra di Musica d’Insieme Jazz.
<hr>
Nel mese di gennaio 2021 è in uscita il suo nuovissimo album di brani originali <strong>“RAPSODIA PER CONTRABBASSO E CORO DI CLARINETTI”</strong>,  realizzato con il Bardaro Clarinettes Ensemble,  diretto dal Maestro Alessandro Eusebio.


</div>

  </div>

  <div v-else class="container">
    <h5>DOUBLE BASS PLAYER – COMPOSER – MUSIC TEACHER</h5>
<h3>Luca Garlaschelli </h3>
<div class="bio">
Luca Garlaschelli attended the Siena Jazz classes and the Double bass course cat the Conservatoire G. Nicolini in Piacenza, where he graduated in 1989.  Since then he started his collaborations with Piero Bassini,  Arrigo Cappelletti, Furio Romano, Carlo Bagnoli, Paolo Tomelleri, Umberto Petrin and many others.  He collaborated and performed with  the baritone Leo Nucci   at Teatro dell’Opera in Rome, Teatro Sistina, Teatro Dante Alighieri, Conservatoire of Zagreb in Sabbioneta.  In the meantime he started his collaboration with Moni Ovadia, performing   at Teatro  Petruzzelli in Bari, at Teatro dei Filodrammatici in Milan, at Mama’s Theater in New York and also taking part in the Venice International Film Festival.  
<hr />
In the course of many decades  his professional collaboration with Moni Ovadia has developed and become  a  deep personal friendship. 
<hr />
In 1999 Luca Garlaschelli got the degree in Jazz Music (Diploma di Alto Perfezionamento di Musica Jazz) from the Conservatoire A. Boito in Parma.  His collaborations with jazz musicians are countless: Harold Land, Bruce Forman, Jimmy Cobb, Giulio Capiozzo, Tullio De Piscopo, Piero Bassini, Paolo Fresu, Tiziana Ghiglioni, Enrico Rava, Gaetano Liguori, Franco Cerri, Enrico Intra, Ettore Fioravanti, Antonello Salis, G. Luigi Trovesi and others.   
He took part in many tours with great jazz musicians such as Steve Turre (August ’91),   Bruce Forman (October ’92, July ’93, October  ’94, July  ’95, November  ’98),   Jimmy Owens (July  ’96, July  ’98),  George Cables (February  ’99), Tony Scott (April  ’98, August  ’99).  He recorded with  Gianni Coscia, Bruno De Filippi, Renato Sellani, Piero Bassini, Roberto Gatto, Ettore Fioravanti, Moni Ovadia, Janosh Husur, Vladimir Denissenkov, Gaetano Liguori, Mario Arcari, Gabriele Mirabassi.  He held live concerts  in Cuba, Germany, Switzerland, France, in the United States, in Poland, Yugoslavia, Turkey, Spain, Denmark and Lebanon.  
<hr />
In 1998 he founded  his  Quartet named  <strong>Musikorchestra</strong>:  Luca Garlaschelli on double bass or on “bassetto” (a cello tuned in fourths),    Enzo Rocco on acoustic guitar, Renata “Tatoo” Vinci on clarinet  - bass clarinet – soprano saxophone,  and  Ferdinando Faraò on percussions.  The repertoire of Musikorchestra ranges from Klezmer music to habanera, from jazz music  to bossa nova, from tango to Romanian popular music. 
<hr />
The relationship of Luca Garlaschelli with the theatre becomes stronger and stronger:  “Moni Waltz”, dedicated to Moni Ovadia, has become the “Leitmotiv” of the theatrical performance by Moni Ovadia “Mame mamele mama. Il crepuscolo delle madri”.   
<hr />
Luca Garlaschelli’s collaborations with international jazz musicians (such as Franco Cerri, Gianni Basso, Gaetano Liguori, Jimmy Owens, Tilmann Dehnhard)  as well as his numerous theatrical performances  have been  recorded  in many albums: 
<hr />
 1999: <strong>“Dont’ forget…”</strong>  (Edizioni Discografiche Audiar) by Musikorchestra.  Special guests: Moni Ovadia, Tiziana Ghiglioni and  Vladimir Denissenkov.
<hr />
2000:   Musikorchestra takes part in the album <strong>“Escuela de Oficios”</strong> produced by Audiar with Cuban musicians,   putting into music the famous Cuban poem  “La Muralla”.
<hr />
2001:  The album <strong>“The suond of dream”</strong> is released and presented in Cannes, Lugano, Bergamo, Piacenza, Arcore, Cassano D’Adda. 
<hr />
2002: Luca Garlaschelli takes part in the performances “<strong>Il violinista sul tetto”</strong>  and in 2003 in ”L’Armata a cavallo”, both directed by Moni Ovadia. 
<hr />
In the meantime Luca Garlaschelli recorded  the album <strong>Il Comandante”</strong>with Gaetano Liguori Trio. This album was  presented at the Unesco in Beirut to commemorate the 20th anniversary of Sabra and Chatila massacre. 
<hr />
2004: Musikorchestra releases its  third album, <strong>“Salam Alaykum”,</strong>  receiving  great acclaim from critics. 
<hr />
2005 and 2006:  Luca Garlaschelli takes part in two theatrical performances with Moni Ovadia, <strong>“Es iz Amerike!”</strong> and  <strong>“Le storie del Signor Keuner”</strong>.
<hr />
2007: Musikorchestra releases the cd-dvd <strong>“Mai tardi”</strong>.
<hr />
Luca Garlaschelli  continues his activity as Jazz Orchestra teacher at CRAMS in Lecco.  
<hr />
In April 2007 he graduated in  Double Bass Soloist at Conservatoire G. Nicolini in Piacenza. 
<hr />
In October 2009 he graduated in Jazz Double bass at Conservatoire G. Verdi in Milan. 
<hr />
2010:  He releases the album “Mingus in Strings” (SNJ Records) revisiting  some compositions by Charles Mingus with a string quintet line-up.    Thanks to  this album Luca Garlaschelli  is mentioned as  double bass player and arranger in the  “Top Jazz” section  of the renowned  magazine “Musica Jazz”. 
<hr />
2012:  He records the cd  “Duology” (SNJ Records)   in duo with  the axophonist Claudio Fasoli. 
<hr />
Luca Garlaschelli  also continues his activity as live performer.
<hr />
In 2012 he records <strong>“Verdi in jazz”</strong>   as double bass player and arranger.  
<hr />
In 2014 he records <strong>“Tammuriata nera, Napoli canzoni e jazz”</strong>.
In the same year he performs at the Theatre “S. Martin” in Buenos Aires with Moni Ovadia’s  Stage Orchestra.   In November 2014 he also performs at the Istituto Italiano di Cultura in New York with his project <strong>“Jazzin’ Around Baroque”</strong> as double bass player and arranger. 
<hr />
He continues his theatrical collaborations as composer and double bass player with Moni Ovadia in tour from 2018 to 2020 with the performance “Dio ride”.  With Cochi Ponzoni in  the performance “Magellano” and   with “Sex and disabled people”  in Madrid, directed by Luciana Littizzetto.
<hr />
Since 2015 Luca Garlaschelli has been teaching at the Conservatoires of Naples, Alessandria, Potenza, Bologna, Mantova and  Cosenza (where he has been and currently is chair of  Jazz Music Ensemble). 
<hr />
2021: in the month of January 2021 he released his latest album of original compositions <strong>“RAPSODIA PER CONTRABBASSO E CORO DI CLARINETTI”</strong>,  recorded with the Bardaro Clarinettes Ensemble  directed by Alessandro Eusebio. 


</div>

  </div>

  </div>
</template>

<script>
import Jumbotron from '@/components/Jumbotron';
export default {
  name: 'About',
  components:{
    Jumbotron
  },
  props:{
    lang:String
  },
  computed:{
    getTitle(){
      if(this.lang === 'it'){
        return 'Biografia'
      }
      return 'Biography'
    }
  }
}
</script>

<style lang="scss" scoped>
.main{
  margin: 45px 0;
}
.bio{
  text-align: justify;
  margin-top:20px
}
hr{
  color: white;
}
</style>